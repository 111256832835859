import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index';
import VueClipboard from 'vue-clipboard2';

import './public-path';
import './plugins';

import businesscomponent from 'business-component-next';
import 'business-component-next/lib/style.css';

Vue.use(businesscomponent, {
  url: process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_BASE_URL
});

Vue.config.productionTip = false;
Vue.use(VueClipboard);

let instance = null;

function render(props = {}) {
  // container 是 qiankun 包装过的微应用容器
  const { container } = props;

  instance = new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount(container ? container.querySelector('#app') : '#app');
}

// 独立运行时
!window.__POWERED_BY_QIANKUN__ && render();
window.unmount = () => unmount();

export async function bootstrap() {
  console.log('系统管理加载');
}

export async function mount(props) {
  render(props);
}

export async function unmount() {
  instance.$destroy();
  instance.$el.innerHTML = '';
  instance = null;
}
