import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const state = {
  store: {}
};

const getters = {
  iptValue: (state) => state.store.callObj
};

const mutations = {
  initStore(state, data) {
    state.store = data;
  },
  setStore(state, data) {
    state.store = {
      ...state.store,
      ...data
    };
  }
};

const actions = {};

export default new Vuex.Store({
  actions,
  getters,
  state,
  mutations,
  modules: {},
  strict: false,
  plugins: []
});
